import {  Toast, NavBar, Icon, PullRefresh, List } from 'vant'

export default {
  name: 'KeepAlive',
  components:{
    'van-nav-bar': NavBar,
    'van-pull-refresh': PullRefresh,
    'van-icon': Icon,
    'van-list': List,
  },
  data () {
    return {
      value1: 0,
      value2: 'a',
      value3: 'a',
      isLoading: false,
      typeList: [],
      loading: true,
      finished: false,
      list:[],
      apis:{
        queryColumn:"/column/queryColumn",//信息栏目
        licedQueryContent:"/content/slicedQueryContent"
      },
      params:{
        code:'',
        pageIndex:1,
        pageSize:10,
        // sortName:'create_date',
        // sortOrder:'asc'

      }
    }
  },
  mounted () {

    this.initType()
  },

  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
//进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter (to, from, next) {
    next(vm => {
      document.body.scrollTop = vm.scrollTop
    })
  },
  beforeRouteLeave (to, from, next){
    this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    next()
  },
  methods: {
    revert(){
      this.$router.go(-1)
    },
    initType(){
      const me = this;
      me.$get({
        url:me.apis.queryColumn,
        params:{
          code:'lswh'
        }
      }).then(rep=>{
        me.typeList = rep;
        me.params.code = me.typeList[0].code
        me.init()
      })
    },
    typeClic(v){//点击类型
      const me = this;
      me.params.code = v;
      me.list=[];
      me.finished = false;
      me.params.pageIndex = 1;
      me.init()
    },
    routerPush(obj){//跳转详情页面
      this.$router.push(`/cultureDetails/?obj=${obj.contentId}`)
    },

    timer(time){//修改时间格式
      return time.slice(0,10 )
    },
    init(){//初始化列表
      const me = this;
      me.$get({
        url:me.apis.licedQueryContent,
        params:me.params
      }).then(rep=>{
        if(!rep.data.length){
          this.finished = true;
        }
        this.loading = false;
        me.list.push(...rep.data)
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    onLoad () {
      const me = this;
      console.log(1);
      let page = me.params.pageIndex;
      page = page ? page + 1 : 1;
      me.params.pageIndex = page;
      me.init();
    },
    tiketRouter () {
      this.$router.push('/recreDetails')
    },
    onRefresh () {
      const me = this;
      setTimeout(() => {
        Toast('刷新成功');
        me.isLoading = false;
        me.list=[]
        me.params.pageIndex = 1;
        this.init()
      }, 1000);
    }
  }
}
